<template>
  <div>
    <content-template
      :menuName="$t('APMA.assignTaskDetail')"
      :backButton="true"
      @backToPage="moveToOther('APMA')"
    >
      <template #form>
        <form>
          <div :class="!isMobile ? 'i-flex-parent' : ''">
            <div :class="`${!isMobile ? 'i-flex-child' : ''}`">
              <Input
                v-model="edited_item.cycle_phase"
                :label="$t('APMA.cyclePhase')"
                class="s-mb-20"
                :disabled="true"
              />
            </div>
            <div :class="`${!isMobile ? 'i-flex-child s-ml-30' : ''}`">
              <Input
                v-model="edited_item.priority"
                :label="$t('APMA.priority')"
                :disabled="true"
                class="s-mb-20"
              />
            </div>
          </div>
          <Textarea
            v-model="edited_item.measure"
            :label="$t('APMA.measure')"
            :disabled="true"
            class="s-mb-20"
          />
          <Textarea
            v-model="edited_item.detail"
            label="Detail"
            :disabled="true"
            class="s-mb-20"
          />

          <div class="s-mb-20">
            <label class="s-input-label">{{
              $t("APMA.stakeholderAgencies")
            }}</label>
            <Multiselect
              v-model="edited_item.stakeholders_agencies"
              tag-placeholder=""
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.stakeholders'
              )}`"
              label="name"
              track-by="id"
              :options="stakeholders_options"
              :multiple="true"
              disabled
              @input="onChangeStakeholder"
            ></Multiselect>
          </div>
          <div class="s-mb-20">
            <label class="s-input-label">{{ $t("APMA.leads") }}</label>
            <Multiselect
              v-model="edited_item.leads"
               tag-placeholder=""
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.stakeholders'
              )}`"
              label="name"
              track-by="id"
              :options="leads_options"
              :multiple="true"
              disabled
            ></Multiselect>
          </div>

          <Textarea
            v-model="edited_item.expected_result"
            :label="$t('APMA.expectedResult')"
            class="s-mb-20"
            :disabled="true"
          />
          <Input
            v-model="edited_item.expected_timeline"
            :label="$t('APMA.expectedTimeline')"
            class="s-mb-20"
            :disabled="true"
          />
          <Textarea
            v-model="edited_item.result"
            :label="$t('APMA.result')"
            class="s-mb-20"
            :disabled="true"
          />
        </form>

        <TableCustom
          :columns="columns"
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>

          <template #action="props">
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
                
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="
                    downloadItem(
                      props.row.url,
                      props.row.filename,
                      props.row.mime_type
                    )
                  "
                >
                  {{ $t("general.downloadFile") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto" v-if="item.status == 'In Review'">
            <Button color="error" @click.prevent="openExpertConsultation()">
              {{ $t("APMA.openExpert") }}
            </Button>
            <Button class="s-ml-20" @click.prevent="onFinish('accept')"
              >{{ $t("general.accept") }}"</Button
            >
          </div>
        </div>
      </template>
    </content-template>

    <Modal v-if="isModal" :title="$t('APMA.chooseExpert')">
      <template #modalContent>
        <div class="s-mtb-30">
          <div class="s-mb-20">
            <label class="s-input-label">{{ $t("sidebar.expertise") }}</label>
            <Multiselect
              @input="onChangeDropdownExpertise"
              v-model="edited_item.expertise_detail"
              label="name"
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.expertise'
              )}`"
              :options="expertise_dropdown.map((type) => type.id)"
              :custom-label="
                (opt) => expertise_dropdown.find((x) => x.id == opt).name
              "
            ></Multiselect>
          </div>
          <div class="s-mb-20">
            <label class="s-input-label">{{ $t("sidebar.experts") }}</label>
            <Multiselect
              label="name"
              v-model="edited_item.expert"
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.experts'
              )}`"
              :options="expert_dropdown.map((type) => type.id)"
              :custom-label="
                (opt) => expert_dropdown.find((x) => x.id == opt).name
              "
              :multiple="true"
            ></Multiselect>
          </div>

          <div class="s-mt-20 s-flex">
            <div class="s-ml-auto">
              <Button class="s-ml-20" @click.prevent="onSubmitOpenCosul"
                >{{$t('general.submit')}}"</Button
              >
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import Multiselect from "vue-multiselect";
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate, Multiselect },
  mixins: [form, tableMixins],
  props: {
    menuName: String,
    inputType: String,
  },
  data() {
    return {
      dialogExpert: true,
      edited_item: {
        cycle_phase: "",
        priority: "",
        measure: "",
        detail: "",
        stakeholders_agencies: [],
        leads: [],
        expected_result: "",
        expected_timeline: "",
        result: "",
        expertise_detail: [],
      },
      file_id: "",
      expert: [],
      selected_row: [],
      columns: [
        {
          label: "No",
          isSortable: false,
          fieldName: "index",
          class: "r-table-firstColumn",
        },
        {
          label: "APMA.fileName",
          fieldName: "filename",
          isSortable: true,
          class: "",
        },
        {
          label: "APMA.fileDesc",
          fieldName: "description",
          isSortable: true,
          class: "",
        },
        {
          label: "general.action",
          isSortable: false,
          fieldName: "action",
          class: "r-table-actionColumn",
        },
      ],
      leads_options: [],
    };
  },
  mounted() {
    this.initData();
    this.getData();
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    isModal() {
      return this.$store.getters["getModal"];
    },
    ...mapGetters({
      item: "actionplan/getActionPlan",
      rows: "actionplan/getFiles",
      expert_dropdown: "expert/getExpert",
      expertise_dropdown: "expertise/getExpertise",
      request: "actionplan/getRequest",
      stakeholders_options: "stakeholder/getStakeholder",
    }),
  },

  methods: {
    ...mapActions({
      setModal: "setModal",
      setActionPlan: "actionplan/setActionPlanSingleData",
      setFiles: "actionplan/setFiles",
      onVerification: "actionplan/onVerify",
      onDownloadFile: "actionplan/downloadFileAction",
      setExpertDropDown: "expert/setExpertDropDown",
      setExpertiseDropDown: "expertise/setExpertiseDropdownData",
      setStakeholder: "stakeholder/setStakeholderDropdown",
    }),

    async onChangeDropdownExpertise(e) {
      await this.setExpertDropDown(e);
    },
    async onFinish(param) {
      const data = {
        id: this.$route.params.id,
        action: param,
      };
      await this.onVerification(data);
      this.$router.push(
        this.$translate({
          name: "APMA",
        })
      );
    },
    async onSubmitOpenCosul() {
      const data = {
        id: this.$route.params.id,
        expert_id: this.edited_item.expert,
        action: "decline",
      };
      await this.onVerification(data);
      this.setModal(false);
      this.$router.push(
        this.$translate({
          name: "APMA",
        })
      );
    },

    openExpertConsultation() {
      this.setModal(true);
    },
    onBack() {
      this.$router.push(
        this.$translate({
          name: "APMA",
        })
      );
    },

    async initData() {
      this.setLoadingPage(true)
      await this.setActionPlan(this.$route.params.id);
      await this.setFiles(this.$route.params.id);
      await this.setStakeholder();
      // await this.setExpertDropDown();
      await this.setExpertiseDropDown();
      this.edited_item.cycle_phase = this.item.cycle_phase_name;
      this.edited_item.priority = this.item.priority_name;
      this.edited_item.measure = this.item.measure;
      this.edited_item.detail = this.item.detail;
      this.edited_item.expected_result = this.item.expected_result;
      this.edited_item.expected_timeline = this.item.expected_timeline;
      this.edited_item.result = this.item.result;
      this.edited_item.leads = this.item.leads;
      this.edited_item.stakeholders_agencies = this.item.stakeholders;
      this.file_id = this.rows.file_id;
       this.setLoadingPage(false)
    },

    async downloadItem(url, label, type) {
      await this.onDownloadFile({ url: url, type: type });
      const blob = this.$store.getters["actionplan/getBlobData"];
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    moveToOther(routerName) {
      this.$router.push(
        this.$translate({
          name: routerName,
        })
      );
    },

    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setFiles(this.$route.params.id);
      this.fetchData = false;
      // const response = this.getResponse("BRRD");
      // if (response.status !== 1) {
      //   this.showSnackbar({
      //     type: "error",
      //     text: response.msg,
      //   });
      // }
    },

    onChangeStakeholder(id) {
      this.leads_options = this.stakeholders_options.filter((x) =>
        id.includes(x.id)
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
