var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-template',{attrs:{"menuName":_vm.$t('APMA.assignTaskDetail'),"backButton":true},on:{"backToPage":function($event){return _vm.moveToOther('APMA')}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('form',[_c('div',{class:!_vm.isMobile ? 'i-flex-parent' : ''},[_c('div',{class:("" + (!_vm.isMobile ? 'i-flex-child' : ''))},[_c('Input',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('APMA.cyclePhase'),"disabled":true},model:{value:(_vm.edited_item.cycle_phase),callback:function ($$v) {_vm.$set(_vm.edited_item, "cycle_phase", $$v)},expression:"edited_item.cycle_phase"}})],1),_c('div',{class:("" + (!_vm.isMobile ? 'i-flex-child s-ml-30' : ''))},[_c('Input',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('APMA.priority'),"disabled":true},model:{value:(_vm.edited_item.priority),callback:function ($$v) {_vm.$set(_vm.edited_item, "priority", $$v)},expression:"edited_item.priority"}})],1)]),_c('Textarea',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('APMA.measure'),"disabled":true},model:{value:(_vm.edited_item.measure),callback:function ($$v) {_vm.$set(_vm.edited_item, "measure", $$v)},expression:"edited_item.measure"}}),_vm._v(" "),_c('Textarea',{staticClass:"s-mb-20",attrs:{"label":"Detail","disabled":true},model:{value:(_vm.edited_item.detail),callback:function ($$v) {_vm.$set(_vm.edited_item, "detail", $$v)},expression:"edited_item.detail"}}),_c('div',{staticClass:"s-mb-20"},[_c('label',{staticClass:"s-input-label"},[_vm._v(_vm._s(_vm.$t("APMA.stakeholderAgencies")))]),_c('Multiselect',{attrs:{"tag-placeholder":"","placeholder":((_vm.$t('general.searchOrAdd')) + " " + (_vm.$t(
              'sidebar.stakeholders'
            ))),"label":"name","track-by":"id","options":_vm.stakeholders_options,"multiple":true,"disabled":""},on:{"input":_vm.onChangeStakeholder},model:{value:(_vm.edited_item.stakeholders_agencies),callback:function ($$v) {_vm.$set(_vm.edited_item, "stakeholders_agencies", $$v)},expression:"edited_item.stakeholders_agencies"}})],1),_c('div',{staticClass:"s-mb-20"},[_c('label',{staticClass:"s-input-label"},[_vm._v(_vm._s(_vm.$t("APMA.leads")))]),_c('Multiselect',{attrs:{"tag-placeholder":"","placeholder":((_vm.$t('general.searchOrAdd')) + " " + (_vm.$t(
              'sidebar.stakeholders'
            ))),"label":"name","track-by":"id","options":_vm.leads_options,"multiple":true,"disabled":""},model:{value:(_vm.edited_item.leads),callback:function ($$v) {_vm.$set(_vm.edited_item, "leads", $$v)},expression:"edited_item.leads"}})],1),_c('Textarea',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('APMA.expectedResult'),"disabled":true},model:{value:(_vm.edited_item.expected_result),callback:function ($$v) {_vm.$set(_vm.edited_item, "expected_result", $$v)},expression:"edited_item.expected_result"}}),_vm._v(" "),_c('Input',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('APMA.expectedTimeline'),"disabled":true},model:{value:(_vm.edited_item.expected_timeline),callback:function ($$v) {_vm.$set(_vm.edited_item, "expected_timeline", $$v)},expression:"edited_item.expected_timeline"}}),_c('Textarea',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('APMA.result'),"disabled":true},model:{value:(_vm.edited_item.result),callback:function ($$v) {_vm.$set(_vm.edited_item, "result", $$v)},expression:"edited_item.result"}})],1),_c('TableCustom',{attrs:{"columns":_vm.columns,"request":_vm.request,"fetchData":_vm.fetchData,"data":_vm.rows},on:{"setSortBy":_vm.setSortBy,"setShow":_vm.setShow,"getData":_vm.getData,"mouseleave":function($event){_vm.popup = null},"togglePopup":_vm.togglePopup},scopedSlots:_vm._u([{key:"index",fn:function(props){return [_vm._v(" "+_vm._s(_vm.curNumber(props.index))+" ")]}},{key:"action",fn:function(props){return [_c('div',{staticClass:"r-menu"},[_c('img',{staticClass:"r-isClickAble",attrs:{"src":require('@/assets/icons/others/more.svg')}}),(_vm.popup == _vm.curNumber(props.index))?_c('div',{staticClass:"r-menu-items",on:{"mouseleave":function($event){_vm.popup = -1}}},[_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.downloadItem(
                    props.row.url,
                    props.row.filename,
                    props.row.mime_type
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("general.downloadFile"))+" ")])]):_vm._e()])]}}])}),_c('div',{staticClass:"s-mt-20 s-flex"},[(_vm.item.status == 'In Review')?_c('div',{staticClass:"s-ml-auto"},[_c('Button',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.openExpertConsultation()}}},[_vm._v(" "+_vm._s(_vm.$t("APMA.openExpert"))+" ")]),_c('Button',{staticClass:"s-ml-20",on:{"click":function($event){$event.preventDefault();return _vm.onFinish('accept')}}},[_vm._v(_vm._s(_vm.$t("general.accept"))+"\"")])],1):_vm._e()])]},proxy:true}])}),(_vm.isModal)?_c('Modal',{attrs:{"title":_vm.$t('APMA.chooseExpert')},scopedSlots:_vm._u([{key:"modalContent",fn:function(){return [_c('div',{staticClass:"s-mtb-30"},[_c('div',{staticClass:"s-mb-20"},[_c('label',{staticClass:"s-input-label"},[_vm._v(_vm._s(_vm.$t("sidebar.expertise")))]),_c('Multiselect',{attrs:{"label":"name","placeholder":((_vm.$t('general.searchOrAdd')) + " " + (_vm.$t(
              'sidebar.expertise'
            ))),"options":_vm.expertise_dropdown.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.expertise_dropdown.find(function (x) { return x.id == opt; }).name; }},on:{"input":_vm.onChangeDropdownExpertise},model:{value:(_vm.edited_item.expertise_detail),callback:function ($$v) {_vm.$set(_vm.edited_item, "expertise_detail", $$v)},expression:"edited_item.expertise_detail"}})],1),_c('div',{staticClass:"s-mb-20"},[_c('label',{staticClass:"s-input-label"},[_vm._v(_vm._s(_vm.$t("sidebar.experts")))]),_c('Multiselect',{attrs:{"label":"name","placeholder":((_vm.$t('general.searchOrAdd')) + " " + (_vm.$t(
              'sidebar.experts'
            ))),"options":_vm.expert_dropdown.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.expert_dropdown.find(function (x) { return x.id == opt; }).name; },"multiple":true},model:{value:(_vm.edited_item.expert),callback:function ($$v) {_vm.$set(_vm.edited_item, "expert", $$v)},expression:"edited_item.expert"}})],1),_c('div',{staticClass:"s-mt-20 s-flex"},[_c('div',{staticClass:"s-ml-auto"},[_c('Button',{staticClass:"s-ml-20",on:{"click":function($event){$event.preventDefault();return _vm.onSubmitOpenCosul.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('general.submit'))+"\"")])],1)])])]},proxy:true}],null,false,3982351564)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }